@import "../../../scss/colors";

.container {
  position: absolute;
  bottom: 100%;
  left: -1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  align-items: flex-start;
  gap: 0.5rem;
  padding-bottom: 0.4rem;

  user-select: none;
  z-index: 999;
}

.icon {
  padding: 0.25rem 0.625rem;
  border-radius: 4px;
}

.item {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  border: 0.0625rem solid map-get($colors, "accsentWd");
  border-radius: 8px;
  background-color: map-get($colors, "baseW");
  padding: 6px 11px 4px 6px;

  &.error {
    color: map-get($colors, "accsent_3S");
    border: 0.0625rem solid map-get($colors, "accsent_3S");

    & .icon  {
      background-color: map-get($colors, "accsent_3S");
    }
  }

  &.comment {
    color: map-get($colors, "accsent_1S");
    border: 0.0625rem solid map-get($colors, "accsent_1S");

    & .icon  {
      background-color: map-get($colors, "accsent_1S");
    }
  }

  &.hovered {
    background-color: map-get($colors, "faintS2");
  }
}

.item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 10rem;
}

.item-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-action {
  min-width: 0.8rem;
  max-width: 0.8rem;
}