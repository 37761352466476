@use "sass:color";
@import "./colors";

@mixin dropdown-input($colorVariant: "primary") {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 0.875rem;
  gap: 0.375rem;
  height: 2.75rem;
  border-radius: 1rem;
  cursor: pointer;
  box-sizing: border-box;

  @if ($colorVariant == "primary") {
    background-color: map-get($colors, "baseW");
  } @else if $colorVariant == "secondary" {
    background-color: map-get($colors, "faintS2");
  }
}

@mixin dropdown-list-container(
  $colorVariant: "primary",
  $flexDirection: "column"
) {
  @include custom-scroll-bar();

  box-shadow: 0 0.625rem 0.9375rem rgba(66, 71, 77, 0.1);
  border-radius: 1rem;
  display: flex;
  align-items: flex-start;
  padding: 0;
  gap: 0.25rem;
  overflow: hidden;
  z-index: 2000;
  max-height: 10rem;
  overflow-y: auto;
  margin-top: 0.375rem;

  @if ($flexDirection == "column") {
    flex-direction: column;
  } @else {
    flex-direction: row;
  }

  @if ($colorVariant == "primary") {
    background-color: map-get($colors, "baseW");
  } @else if $colorVariant == "secondary" {
    background-color: map-get($colors, "faintS2");
  }
}

@mixin dropdown-item-container($colorVariant: "primary") {
  display: flex;
  align-items: center;
  padding: 0.75rem 0.875rem;
  gap: 0.375rem;
  cursor: pointer;
  width: 100%;
  color: map-get($colors, "baseDl");
  box-sizing: border-box;

  &:hover {
    @if ($colorVariant == "primary") {
      background-color: map-get($colors, "faintsS2Back");
      color: map-get($colors, "baseD");
    } @else if ($colorVariant == "secondary") {
      background-color: map-get($colors, "faintsS2Back");
      color: map-get($colors, "baseD");
    }
  }
}

@mixin progress-grid-pattern() {
  background-image: -webkit-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
}

@mixin custom-scroll-bar() {
  /* width */
  ::-webkit-scrollbar {
    width: 0.25rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: map-get($colors, "faintW");
    margin: 1rem 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: map-get($colors, "faintsS");
  }
}
