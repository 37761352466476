.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
  background: var(--white-hex);
  border-radius: 16px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 4px 53px rgba(0, 0, 0, 0.08);
}

@media (max-width: 600px) {
  .container {
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 16px;
  }
}