@font-face {
  font-family: "Golos";
  font-display: "swap";
  src: local("Golos"), url(./fonts/Golos-Text_Regular.woff) format("woff"),
    url(./fonts/Golos-Text_Regular.woff2) format("woff2");
}

body {
  font-family: "Golos", "Roboto", "Helvetica", "Arial", sans-serif !important;
}
