@use "sass:color";
@import "../../scss/colors";
@import "../../scss/dimensions";

.container {
  display: flex;
  flex-direction: column;
  background-color: map-get($colors, "baseW");
  padding: 1.875rem;
  border-radius: 1.25rem;
  gap: 0.75rem;
}

@media screen and (max-width: $mobile-screen-width) {
  .container {
    padding: 1rem 0;
  }
}
