@import "../../../scss/dimensions";

.container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;

  & > button {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: $tablet-screen-width) {
  .container {  
    flex-direction: column;
  }
}