@import "../../../scss/dimensions";
@import "../../../scss/colors";

.top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 2rem;
}

.word {
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
  line-height: 1.875rem;

  &.hovered {
    background-color: map-get($colors, "faintS2");
  }

  &.error {
    background-color: rgba($color: map-get($colors, "accsent_3S"), $alpha: 0.3);
    color: map-get($colors, "accsent_3S");
  }

  &.comment {
    background-color: rgba($color: map-get($colors, "accsent_1S"), $alpha: 0.3);
    color: map-get($colors, "accsent_1S");
  }
}

.word-container {
  position: relative;
}

.editable-word-container {
  position: relative;
}

.editable-word {
  display: inline-flex;
  outline: 0.1rem solid map-get($colors, "accsent_1S");
  line-height: 1.875rem;
  gap: 0.3rem;
  flex-wrap: wrap;
}

.icon {
  display: none;
}

@media screen and (max-width: $tablet-screen-width) {
  .icon {
    display: inline-flex;
  }
}
