@import "../../scss/dimensions";
@import "../../scss/colors";

.container {
  overflow: visible;
  position: absolute;
  inset: unset;
  border: 0.0625rem solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  border-radius: 1.25rem;
  outline: none;
  padding: 1.25rem;
  width: 100%;
  max-width: 70rem;
  margin-top: 5rem;
}

.message {
  margin-top: 1.5rem;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 32px;
}

.title {
  flex: 1;
}

.cards {
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 16px;
  row-gap: 21px;
}

.task,
.answer,
.mistakes {
  padding: 16px 24px;
  background-color: map-get($colors, "baseW");
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(130, 169, 188, 0.08),
    0px 5px 50px 0px rgba(0, 67, 101, 0.08);
}

.task {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.answer {
  grid-column-start: 1;
}

.mistakes {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 2;

  display: grid;
  grid-template-rows: repeat(2, 1fr);
}

.student {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.unit {
  display: flex;
  gap: 8px;
  align-items: center;
}

.mistakes-items {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  gap: 1rem;
}

.mistakes-hover {
  padding-top: 1rem;
  border-top: 1px solid map-get($colors, "menu_border");
  display: flex;
  flex-direction: column;
}

.mistakes-hover-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

@media screen and (max-width: $tablet-screen-width) {
  .cards {
    grid-template-columns: 1fr;
    column-gap: 0px;
    row-gap: 0px;
    gap: 1rem;
  }

  .task,
  .answer,
  .mistakes {
    padding: 0;
    background-color: map-get($colors, "baseW");
    box-shadow: none;
    border-radius: 0px;
    border-bottom: 1px solid map-get($colors, "faintsW");
  }

  .answer {
    grid-column-start: unset;
  }

  .mistakes {
    grid-row-start: 2;
    grid-row-end: unset;
    grid-column-start: unset;

    display: grid;
    grid-template-rows: repeat(2, 1fr);
  }

  .mistakes-items {
    margin-bottom: 1rem;
  }
}
