@mixin color-class($color-name, $color-value) {
  .#{$color-name} {
    color: $color-value;
  }
}

// Define color variables
$colors: (
  baseD: #42474d,
  baseDl: #828d99,
  faintW: #f2f4f7,
  faintsS: #a6acb3,
  faintS2: #e9edf5,
  faintsW: #f0f0f0,
  faintsWd: #dae3f1,
  faints_2WD: #f0f0f0,
  baseW: #ffffff,
  faintsS2Back: #c3d3e5,
  accsent_1S: #54b2de,
  accsent_1Wd: #99cae1,
  accsent_1W: #ceeaf8,
  accsent_2S: #28d03b,
  accsent_2Wd: #73e07f,
  accsent_2W: #a7f5b0,
  accsent_2WD: #73e07f,
  accsent_3S: #d1000a,
  accsentWd: #e7686e,
  accsentW: #f6aeb1,
  accsent_4S: #ffa007,
  accsent_4Wd: #fdc469,
  accsent_4W: #ffe3b6,
  multiVs: #562c8e,
  multiVwd: #9176b5,
  multiVw: #ccbce1,
  multiGs: #28d03b,
  multiGwd: #73e07f,
  multi_2Gw: #a7f5b0,
  wet_asphalt: #78798b,
  check_error: #ff6b4a,
  blue: #5783f1,
  main_color: #54b2de,
  header_bg: #363636,
  menu_border: #d7d7d7,
);

// Generate color classes
@each $color-name, $color-value in $colors {
  @include color-class($color-name, $color-value);
}
