@use "sass:color";
@import "../../scss/colors";

.unit-content-right-top {
  border-radius: 1rem;
  box-shadow: 0px 2px 6px 0px #82a9bc14, 0px 5px 30px 0px #00436514;
}

.unit-content-right-title {
  background-color: #fff1ec;
  padding: 0.875rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  text-align: center;
}

.unit-content-right-middle,
.unit-content-right-bottom {
  text-align: center;
}

.unit-content-right-middle {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-bottom: 1px map-get($colors, "faintsS2Back") solid;
}

.unit-content-right-bottom {
  display: grid;
  grid-template-columns: repeat(4, 85.77px);
}

.unit-content-right-middle > *,
.unit-content-right-bottom > * {
  padding: 0.875rem;
}

.unit-content-right-middle > *:not(:last-child),
.unit-content-right-bottom > *:not(:last-child) {
  border-right: 1px map-get($colors, "faintsS2Back") solid;
}
