@import "../../scss/colors";

.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  padding: 1rem;
  border-radius: 0.875rem;

  &.error {
    background-color: map-get($colors, "accsent_3S");
  }

  &.info {
    background-color: map-get($colors, "accsent_1S");
  }

  &.success {
    background-color: map-get($colors, "accsent_2S");
  }
}

.close {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}