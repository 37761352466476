@use 'sass:color';
@import '../../../scss/colors';
@import '../../../scss/mixins';

.progress {
  width: 100%;
  overflow: hidden;
  height: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 0.125rem;
}

.item {
  display: flex;
  height: 100%;
  flex: 1;
  border-radius: 1.25rem;
  background: #A6ACB3;

  &.white {
    background-color: map-get($colors, 'baseW');
  }

  &.grey {
    background-color: map-get($colors, 'faintS2');
  }

  &.filled {
    &.purple {
      background-color: map-get($colors, 'multiVs');
      @include progress-grid-pattern();
    }

    &.yellow {
      background-color: map-get($colors, 'accsent_4Wd');
    }
  }
}

.subtitle {
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
}