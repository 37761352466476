@use 'sass:color';
@import '../../../scss/colors';
@import '../../../scss/mixins';

.progress {
  width: 100%;
  overflow: hidden;
  height: 1.5rem;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.125rem;
  box-sizing: border-box;

  &.white {
    background-color: map-get($colors, 'baseW');
  }

  &.grey {
    background-color: map-get($colors, 'faintS2');
  }
}
.progress-bar {
  width: 0;
  height: 1.25rem;
  text-align: center;
  border-radius: 1rem;

  &.green {
    background-color: map-get($colors, 'multiGwd');
    @include progress-grid-pattern();
  }

  &.blue {
    background-color: map-get($colors, 'accsent_1Wd');
  }
}

.progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
  -moz-animation: progress-bar-stripes 2s linear infinite;
}
.subtitle {
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
}