@import "../../../scss/dimensions";

.checking-history {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.5rem;
}

.checking-history-item {
  display: flex;
  padding: 0 1.5rem;
  gap: 1rem;
  flex-wrap: wrap;
}

.accordion {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

@media screen and (max-width: $tablet-screen-width) {
  .checking-history {
    gap: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .checking-history-item {
    gap: 0.5rem;
  }
}