@use "sass:color";
@import "../../scss/colors";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 0.625rem;
  width: 100%;
  box-sizing: border-box;
}

.wrapper {
  padding: 0 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  height: 2.75rem;
  background-color: map-get($colors, "baseW");
  border-radius: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.wrapper-primary {
  background-color: map-get($colors, "baseW");
}

.wrapper-secondary {
  background-color: map-get($colors, "faintS2");
}

.input {
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  padding: 0;
  color: map-get($colors, "baseD");
  box-sizing: border-box;
}

.icon {
  color: map-get($colors, "accsent_1Wd");
}

.placeholder {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding-top: 0.125rem;
  opacity: 0.8;
  color: map-get($colors, "baseDl");
}

.input-disabled,
.icon-disabled,
.placeholder-disabled {
  color: map-get($colors, "faintS2");
}

.input-error,
.icon-error,
.placeholder-error {
  color: map-get($colors, "accsentWd");
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
}

.error-wrapper {
  display: flex;
  align-items: center;
  padding: 0 0.875rem;
  gap: 0.3125rem;
}

.error-text {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
}
