.GroupStatistics {
    display: grid;
    grid-template-columns: 1fr 2fr 1.3fr;
    padding: 0.5rem;
    background: var(--white-hex);
    border-radius: 16px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.11);
    overflow: auto;
    margin: 0;
}

.GroupStatistics__col {
    padding: 1rem;
}

.GroupStatistics__col + .GroupStatistics__col {
    border-left: 2px solid #D7D7D7;
}

.GroupStatistics__accent {
    color: var(--primary-100-hex);
}

@media (max-width: 800px) {
    .GroupStatistics {
        display: block;
    }
    .GroupStatistics__col + .GroupStatistics__col {
        border-top: 2px solid #D7D7D7;
        border-left: 0;
    }
}