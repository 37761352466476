@use "sass:color";
@import "../../scss/colors";

.container {
  width: 100%;
  height: 2.75rem;
  border-radius: 0.875rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  font-style: normal;
  font-weight: 700;
  outline: none;
  border: none;

  & span:hover {
    text-decoration: none;
  }
}

.main {
  font-weight: 400;
  background-color: map-get($colors, "accsent_3S");
  color: map-get($colors, "baseW");
  &:hover {
    background-color: color.scale(
      map-get($colors, "accsent_3S"),
      $lightness: 20%
    );
  }
}

.secondary {
  font-weight: 400;
  background: linear-gradient(46.25deg, #207894 0%, #2ecdff 100%);
  color: map-get($colors, "baseW");
  &:hover {
    background: linear-gradient(
      46.25deg,
      color.scale(#207894, $lightness: 20%) 0%,
      #2ecdff 100%
    );
  }
}

.soft {
  color: map-get($colors, "accsent_3S");
  border: 1px solid map-get($colors, "accsent_3S");
  &:hover {
    border-color: color.scale(map-get($colors, "accsent_3S"), $lightness: 20%);
  }
}

.soft-secondary {
  color: map-get($colors, "accsent_1S");
  border: 1px solid map-get($colors, "accsent_1S");
  &:hover {
    border-color: color.scale(map-get($colors, "accsent_1S"), $lightness: 20%);
  }
}

.text {
  color: map-get($colors, "accsent_3S");
  text-transform: uppercase;

  &:hover {
    color: color.scale(map-get($colors, "accsent_3S"), $lightness: 20%);
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
