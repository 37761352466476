@import "../../../scss/dimensions";
@import "../../../scss/colors";

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  cursor: pointer;

  & > div {
    padding: 4px 10px;
    border-radius: 4px;
    background-color: map-get($colors, "accsentWd");

    &.comment {
      background-color: map-get($colors, "accsent_1S");
    }
  }
}

.item {
  width: 2.25rem;
  height: 1.5rem;

  &.hovered {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: map-get($colors, "baseW");
  }
}