@import "colors";

// Font weights
// Regular font weight
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Font sizes
$font-size-root: 16px; // Base font size for the document, typically set on the html element
$font-size-base: 1rem; // 16px
$font-size-sm: 0.875rem; // 14px
$font-size-lg: 1.125rem; // 18px
$font-size-xl: 1.375rem; // 22px

$color-primary: map-get($colors, "baseD");

// Line heights
$line-height-base: 1.5;
$line-height-heading: 1.2;

// Headings
$h1-font-size: 2.5rem; // 40px
$h2-font-size: 2rem; // 32px
$h3-font-size: 1.75rem; // 28px
$h4-font-size: 1.5rem; // 24px
$h5-font-size: 1.25rem; // 20px
$h6-font-size: 1rem; // 16px

// Set base font settings
html {
  font-size: $font-size-root;
}

body {
  font-size: $font-size-base;
  font-weight: $font-weight-regular;
  line-height: $line-height-base;
  color: $color-primary;
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-semibold;
  line-height: $line-height-heading;
  margin-top: 0;
  margin-bottom: 0.5em;
}

h1 {
  font-size: $h1-font-size;
}
h2 {
  font-size: $h2-font-size;
}
h3 {
  font-size: $h3-font-size;
}
h4 {
  font-size: $h4-font-size;
}
h5 {
  font-size: $h5-font-size;
}
h6 {
  font-size: $h6-font-size;
}

// Paragraph
p {
  margin-top: 0;
  margin-bottom: 1em;
}

// Link styles
a {
  color: $color-primary;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

// Small text
small {
  font-size: $font-size-sm;
}

// Bold text
strong {
  font-weight: $font-weight-bold;
}
