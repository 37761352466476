@use "sass:color";
@import "../../scss/colors";

.result-container > *:not(:last-child),
.result-block > .result:not(:last-child) {
  border-right: 1px map-get($colors, "faintsS2Back") solid;
}

.result-container {
  border-radius: 1rem;

  display: flex;
  box-shadow: 0px 2px 6px 0px #82a9bc14, 0px 5px 30px 0px #00436514;
}

.result {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 64px;
  background-color: map-get($colors, "baseW");
  width: 100%;
}

.result-container-enabled .result {
  transition: background-color 0.3s;
  cursor: pointer;
}

.result-container-enabled .result-block-container:hover .result {
  background-color: map-get($colors, "accsent_1W");
}

.result-container-enabled .result-block-container:hover .result-icon {
  opacity: 0;
}

.result-block-container {
  position: relative;
  overflow: hidden;
}

.result-block-container:first-child {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.result-block-container:last-child {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.result-block {
  display: grid;
  grid-template-columns: repeat(2, 85.775px);
}

.result-icon {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  transition: opacity 0.3s;
  pointer-events: none;
}
