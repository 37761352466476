@use "sass:color";
@import "../../scss/colors";

.container {
  padding: 1rem 1.25rem;
  border-radius: 1rem;
  background-color: map-get($colors, "baseW");
  box-shadow: 0px 2px 6px 0px #82a9bc14, 0px 5px 50px 0px #00436514;
  cursor: pointer;
}

.name,
.left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.results {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.exercises {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.arrow {
  transition: transform 0.3s ease-in-out;
  transform: rotate(90deg);
}

.arrow-open {
  transform: rotate(0);
}
