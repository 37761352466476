@import "../../../scss/colors";

.container {
  position: absolute;
  left: 0;
  bottom: 100%;
  padding: 0.5rem;
  user-select: none;
  border-radius: 0.5rem;
  background-color: map-get($colors, "baseW");
  border: 0.1rem solid map-get($colors, "accsent_3S");
  overflow: hidden;
  z-index: 999;
  margin-bottom: 0.4rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &.error {
    border: 0.1rem solid map-get($colors, "accsent_3S");
  }

  &.comment {
    border: 0.1rem solid map-get($colors, "accsent_1S");
  }
}

.icon {
  background-color: currentColor;
  padding: 0.25rem 0.625rem;
  border-radius: 4px;
}

.editor-header {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;

  &.error {
    color: map-get($colors, "accsent_3S");
  }

  &.comment {
    color: map-get($colors, "accsent_1S");
  }
}

.editor-header-title,
.editor-header-actions {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.editor-header-actions {
  cursor: pointer;
  gap: 1rem;
}

.editor-header-action {
  transform: scale(1.5);
}

.editor-input {
  min-width: 15rem;
  min-height: 6rem;
  display: block;
  outline: none;
  padding: 0.5rem;
  resize: none;
}

.item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 0 -0.5rem;
  padding: 0.5rem 1rem;
  margin-bottom: -0.5rem;
  min-width: 10rem;

  &:first-child {
    margin-top: -0.5rem;
  }

  &:not(:last-child) {
    border-bottom: 0.05 rem solid map-get($colors, "baseDl");
  }

  &.error {
    color: map-get($colors, "accsent_3S");
  }

  &.comment {
    color: map-get($colors, "accsent_1S");
  }

  &:hover {
    color: map-get($colors, "baseW");
    &.error {
      background-color: map-get($colors, "accsent_3S");
    }
  
    &.comment {
      background-color: map-get($colors, "accsent_1S");
    }
  }
}