@import "../../../scss/colors";

.container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.comment {
  border-left: 1px solid map-get($colors, "blue");
  padding-left: 8px;
}

.input {
  resize: none;
  display: flex;
  border: 1px solid map-get($colors, "accsent_1S");
  outline: none;
  padding: 0.5rem;

  min-width: 15rem;
  min-height: 6rem;
}