@import "../../../scss/dimensions";
@import "../../../scss/colors";

.item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.item-icon {
  padding: 4px 10px;
  border-radius: 4px;
  background-color: map-get($colors, "accsentWd");
}