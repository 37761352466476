@use "sass:color";
@import "../../scss/colors";

.container {
  position: relative;
}

.tooltip {
  position: absolute;
  top: calc(100% + 0.3rem);
  right: 0.5rem;

  display: flex;
  padding: 0.4rem 0.6rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
  background-color: map-get($colors, "faints_2WD");
  border-radius: 0.25rem;
}

.container:hover {
  & .tooltip {
    opacity: 1;
    pointer-events: all;
  }
}
