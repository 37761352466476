.container {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}