@use "sass:color";
@import "../../scss/colors";

.container {
  font-style: normal;
}

.clickable {
  cursor: pointer;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.h1-desctop {
  font-weight: 400;
  font-size: 1.625rem;
  line-height: 2rem;
}

.h1-mob {
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 1.6875rem;
}

.h2-mob {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.h3-mob {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.375rem;
}

.text-1-regular {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
}

.text-1-bold {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  text-transform: uppercase;
}

.text-1-semibold {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
}

.text-1-link {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  text-decoration-line: underline;
}

.text-2-regular {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 17px;
}

.text-2-medium {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.0625rem;
}

.text-2-semibold {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.0625rem;
}

.text-2-link {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  text-decoration-line: underline;
}

.text-3-regular {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.9375rem;
}

.text-3-link {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  text-decoration-line: underline;
}

.text-3-medium {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.938rem;
}

.text-3-semibold {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.938rem;
}

.text-3-bold {
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.9375rem;
}

.text-4-semibold {
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 0.75rem;
}

.text-4-bold {
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 0.75rem;
  text-transform: uppercase;
}

.text-4-bold-16 {
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 0.75rem;
  text-indent: 1rem;
  text-transform: uppercase;
}

.text-4-link {
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 0.75rem;
  text-decoration-line: underline;
}

.base-w {
  color: map-get($colors, "baseW");
}

.base-d {
  color: map-get($colors, "baseD");
}

.base-dl {
  color: map-get($colors, "baseDl");
}

.accsent-1s {
  color: map-get($colors, "accsent_1S");
}

.accsent-2s {
  color: map-get($colors, "accsent_2S");
}

.accsent-2wd {
  color: map-get($colors, "accsent_2Wd");
}

.accsent-3s {
  color: map-get($colors, "accsent_3S");
}

.accsent-4s {
  color: map-get($colors, "accsent_4S");
}

.accsent-4wd {
  color: map-get($colors, "accsent_4Wd");
}

.accsent-wd {
  color: map-get($colors, "accsentWd");
}

.faints-s {
  color: map-get($colors, "faintsS");
}

.faints-s2-back {
  color: map-get($colors, "faintsS2Back");
}

.wet-asphalt {
  color: map-get($colors, "wet_asphalt");
}

.gradient-1 {
  background: linear-gradient(46.25deg, #207894 0%, #2ecdff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.check-error {
  color: map-get($colors, "check_error");
}

.blue {
  color: map-get($colors, "blue");
}

.main-color {
  color: map-get($colors, "main_color");
}

.header-bg {
  color: map-get($colors, "header_bg");
}
