@import "../../../scss/colors";

.container {
  position: absolute;
  bottom: 100%;
  left: -1rem;
  background-color: map-get($colors, "baseW");
  border: 0.1rem solid map-get($colors, "accsent_1S");
  border-radius: 0.5rem;

  display: flex;
  gap: 1rem;

  align-items: flex-start;
  gap: 0.5rem;
  padding: 0.5rem;
  min-width: 10rem;

  user-select: none;
}