@use "sass:color";
@import "../../scss/colors";

.unit {
  margin-bottom: 1.75rem;
}

.unit-title {
  display: block;
  margin-bottom: 0.5rem;
}

.unit-content {
  display: flex;
  align-items: flex-start;
  gap: 1.25rem;
}

.unit-content-left {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 0.5rem;
}

.unit-content-left-item {
  padding: 0.5rem 1.25rem;
  border-radius: 1rem;
  background-color: #fff1ec;
}

.unit-content-students {
  background-color: #fff1ec;
  height: 156.64px;

  display: flex;
  align-items: center;
}

.unit-content-right {
  overflow-x: auto;

  margin: -2rem 0 0;
  padding: 2rem 0 1rem;
}

.unit-content-right-row {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 0.5rem;
}
