@import "../../../scss/colors";

.word {
  background-color: map-get($colors, "accsent_1S");
  color: map-get($colors, 'baseW');
  padding: 0.5rem 0.1rem;
  line-height: 1.875rem;
}

.word-clear {
  padding: 0.5rem 0.5rem;
  cursor: pointer;
}