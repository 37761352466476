@use "sass:color";
@import "./scss/colors";

.loading {
  margin: 0 auto;
  height: 3rem !important;
  width: 3rem !important;
}

.header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.actions {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.courses {
  display: flex;
  flex-direction: column;
}

.students-filter {
  width: max-content;
}
