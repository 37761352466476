@use "sass:color";
@import "../../scss/colors";
@import "../../scss/dimensions";

.modal-overlay {
  position: fixed;
  inset: 0;
}

.modal-body {
  max-width: 50vw;
  transform: translateX(-100%);
  transition: transform 0.1s ease-in-out;
  background-color: map-get($colors, "baseW");
  height: 100%;
  padding: 1.75rem 1.5rem;
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  box-shadow: 0px 2px 6px 0px #82a9bc14, 0px 5px 50px 0px #00436514;
  outline: none;
  overflow-y: auto;

  &.modal-body--after-open {
    transform: translateX(0);
  }

  &.modal-body--before-close {
    transform: translateX(-100%);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.progress {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
}

.blocks-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.blocks-title {
  padding: 1rem 1.25rem;
  border-radius: 1rem;
  background-color: map-get($colors, "faintsWd");
}
